import React from 'react';

const Avatar = ({ className }) => {
    return(
        <svg fill="white" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 26" width="400px" height="400px">
        <path d="M 13 2.09375 C 7.882813 2.09375 3.316406 5.242188 4.125 12.96875 C 4 13.078125 3.878906 13.175781 3.78125 13.3125 C 3.328125 
        13.957031 3.09375 14.777344 3.09375 15.6875 C 3.09375 16.816406 3.742188 17.722656 4.4375 18.34375 C 4.941406 18.792969 5.464844 
        19.054688 6.03125 19.21875 C 6.433594 21.398438 7.609375 23.097656 8.96875 24.15625 C 9.71875 24.742188 10.496094 25.148438 11.1875
         25.4375 C 11.878906 25.726563 12.445313 25.90625 13 25.90625 C 13.554688 25.90625 14.144531 25.722656 14.84375 25.4375 C 15.542969 
         25.152344 16.304688 24.734375 17.0625 24.15625 C 18.425781 23.117188 19.59375 21.445313 19.96875 19.3125 C 20.601563 19.132813 21.160156 
         18.808594 21.65625 18.3125 C 22.320313 17.648438 22.90625 16.75 22.90625 15.6875 C 22.90625 14.816406 22.664063 14.023438 22.21875 13.375 
         C 22.113281 13.222656 21.980469 13.09375 21.84375 12.96875 C 22.507813 5.242188 18.210938 2.09375 13 2.09375 Z M 9.03125 8.0625 C 11.914063 
         13.722656 16.136719 9.386719 17.96875 9.03125 C 18.757813 10.03125 19.230469 11.414063 19.3125 13.15625 C 19.339844 13.621094 19.722656 
         13.988281 20.1875 14 C 20.328125 14 20.511719 14.074219 20.71875 14.375 C 20.925781 14.675781 21.09375 15.160156 21.09375 15.6875 C 21.09375 
         16.023438 20.808594 16.597656 20.375 17.03125 C 19.941406 17.464844 19.363281 17.6875 19.3125 17.6875 C 18.835938 17.683594 18.4375 18.054688 
         18.40625 18.53125 C 18.277344 20.421875 17.203125 21.808594 15.96875 22.75 C 15.351563 23.222656 14.710938 23.554688 14.15625 23.78125 C 13.601563 
         24.007813 13.09375 24.09375 13 24.09375 C 12.90625 24.09375 12.421875 24.007813 11.875 23.78125 C 11.328125 23.554688 10.671875 23.195313 10.0625 
         22.71875 C 8.839844 21.765625 7.769531 20.363281 7.59375 18.40625 C 7.546875 17.941406 7.15625 17.589844 6.6875 17.59375 C 6.773438 17.59375 6.109375 
         17.40625 5.65625 17 C 5.203125 16.59375 4.90625 16.058594 4.90625 15.6875 C 4.90625 15.097656 5.054688 14.625 5.25 14.34375 C 5.445313 14.0625 5.640625 
         14 5.8125 14 C 6.277344 13.988281 6.660156 13.621094 6.6875 13.15625 C 6.726563 12.289063 6.875 11.527344 7.09375 10.84375 C 7.675781 10.648438
          8.160156 10.402344 8.9375 8.125 C 8.96875 8.101563 9 8.085938 9.03125 8.0625 Z M 20.875 11.84375 C 20.953125 12.039063 21.015625 12.257813 
          21.09375 12.46875 C 21.050781 12.449219 21.011719 12.421875 20.96875 12.40625 C 20.949219 12.214844 20.902344 12.03125 20.875 11.84375
           Z M 9.5 14 C 8.671875 14 8 14.671875 8 15.5 C 8 16.328125 8.671875 17 9.5 17 C 10.328125 17 11 16.328125 11 15.5 C 11 14.671875 10.328125 14 9.5 14 Z M 16.5 14
            C 15.671875 14 15 14.671875 15 15.5 C 15 16.328125 15.671875 17 16.5 17 C 17.328125 17 18 16.328125 18 15.5 C 18 14.671875 17.328125 14 16.5 14 Z"/></svg>


  )
}

export default Avatar

 